import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axiosInstance from "../axios.instance";
import {
  apiUrl,
  apiUrlV2,
  getCloudFrontURL,
  getImageUrl,
  getUserInfo,
} from "../utils/getPaths";
import { endPoints } from "../utils/apiEndPoints";
import { getToaster } from "../utils/general";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FaceAnimationButton from "../components/dashboard/FaceAnimationButton";
import { statics } from "../utils/statics";
import OverlayButton from "../components/dashboard/OverlayButton";
import FaceMeshButton from "../components/dashboard/FaceMeshButton";
import FaceAvatarButton from "../components/dashboard/FaceAvatarButton";
import { Player } from "video-react";
import Button from "../components/formComponents/Button";
import { NavLink } from "react-router-dom";

interface videoAnimConfig {
  background: boolean;
  foreground: boolean;
}

interface faceAnimConfig {}

export interface AnimationButtonData {
  type: string;
  title: string;
  rootPath: string;
  duration: number;
  origin: string;
  config: videoAnimConfig | faceAnimConfig;
  isAdded?: boolean;
}

export interface FaceButtonData {
  _id: string;
  type: string;
  title: string;
  rootPath: string;
  origin: string;
  config: videoAnimConfig | faceAnimConfig;
  isAdded?: boolean;
}

const ARStudioMain = styled("div")``;

const ARStudioInsider = styled("div")`
  width: 50%;
  padding: 0 15px;
  @media (max-width: 1200px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ARStudioInsiderWrapper = styled("div")`
  min-height: 200px;
  height: calc(100vh - 300px);
  max-height: 46vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 14px;
  margin-right: -14px;
`;

const InformativeWrapper = styled("div")`
  width: 100%;
  padding: 0 15px;
  margin-top: 20px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
`;

const ARStudioWrappper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;

const ARStudio = () => {
  const [loading, setLoading] = useState(true);
  const [animationButtons, setAnimationButtons] = useState<
    AnimationButtonData[]
  >([]);
  const [faceButtons, setFaceButtons] = useState<FaceButtonData[]>([]);
  const [selectionList, setSelectionList] = useState<any[]>([]);
  const [isSettingsSet, setIsSettingsSet] = useState<boolean>(true);
  const navigate = useNavigate();
  const [targetOriginScene, setTargetOriginScene] = useState({
    transition: false,
    targetScene: "",
    originScene: "",
  });
  const [activeAvatar, setActiveAvatar] = useState({
    avatarId: "",
    assetUrl: "",
  });
  const baseUrl = statics.CLOUDFRONT_URL + "/";

  const getDownloadedOverlays = async () => {
    await axiosInstance
      .get(`${apiUrlV2(endPoints.inventory)}/${getUserInfo()?.twitchId}`)
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        setAnimationButtons(
          res.data.data[0]?.animations &&
            res.data.data[0]?.animations.length > 0
            ? res.data.data[0].animations
            : []
        );
        setFaceButtons(
          res.data.data[0]?.faces && res.data.data[0]?.faces.length > 0
            ? res.data.data[0].faces
            : []
        );
        if (res.data.data[0]?.animations?.length > 0) {
          const mappedData = res.data.data[0]?.animations?.map(
            (button: any) => {
              // Check for ButtonDataRegular type
              if (button?.type === "videoAnim") {
                return {
                  label: button?.title,
                  foreground: button?.config?.foreground
                    ? getCloudFrontURL(`${button?.rootPath}foreground.webm`)
                    : "",
                  background: button?.config?.background
                    ? getCloudFrontURL(`${button?.rootPath}background.webm`)
                    : "",
                  thumbnail: button?.config?.thumbnail
                    ? getCloudFrontURL(`${button?.rootPath}thumbnail.jpg`)
                    : "",
                  value: button?.title,
                };
              } else {
                return {
                  label: button?.title,
                  modelURL: getCloudFrontURL(`${button?.rootPath}model.glb`),
                  thumbnail: getCloudFrontURL(
                    `${button?.rootPath}thumbnail.jpg`
                  ),
                  value: button?.title,
                };
              }
            }
          );
          setSelectionList(mappedData);
        }
      })
      .catch((err) => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        getToaster("error", "Error while getting your overlays list.");
        throw err;
      });
  };

  const getAllSettings = async () => {
    await axiosInstance
      .get(`${apiUrl(endPoints.settings)}/${getUserInfo()?.twitchId}`)
      .then((res) => {
        if (res.data === null || !res.data) {
          setIsSettingsSet(false);
        }
        if (res.data) {
          setTargetOriginScene({
            transition: res.data?.transition,
            targetScene: res.data?.targetScene,
            originScene: res.data?.originScene,
          });
        }
        if (res.data && res.data.activeAvatar) {
          setActiveAvatar(res.data.activeAvatar);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getDownloadedOverlays();
    getAllSettings();
  }, []);

  return (
    <ARStudioMain>
      {isSettingsSet === false && (
        <div
          className="mb-4"
          style={{
            background: "rgba(255, 193, 7, 28%)",
            border: "1px solid #ffc107",
            borderRadius: 8,
            padding: "5px 10px",
          }}
        >
          Please set up your camera settings first.{" "}
          <NavLink
            to="/settings"
            style={{
              color: "rgba(255, 193, 7, 100%)",
              fontWeight: 700,
              textDecoration: "underline",
            }}
          >
            Go to Settings
          </NavLink>
        </div>
      )}
      <ARStudioWrappper>
        <ARStudioInsider>
          <div className="card-overlay-box p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h3 className="common-title mb-0">Animations</h3>
              {(animationButtons?.length > 1 ||
                (animationButtons?.length > 0 &&
                  !loading &&
                  animationButtons.filter(
                    (animationElement) =>
                      animationElement.origin !== "custom" ||
                      animationElement.isAdded
                  ).length > 1)) && (
                <span
                  style={{
                    cursor: "pointer",
                    textTransform: "uppercase",
                    letterSpacing: 2,
                    color: "#7cd959",
                  }}
                  onClick={() => navigate("/marketplace")}
                >
                  + Add More
                </span>
              )}
            </div>
            <ARStudioInsiderWrapper className={"play_overlays"}>
              {loading && (
                <div className="add-more-anm-face">
                  <Typography align="center">
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  </Typography>
                </div>
              )}
              {!loading &&
                (animationButtons?.length <= 1 ||
                  (animationButtons?.length > 0 &&
                    !loading &&
                    animationButtons.filter(
                      (animationElement) =>
                        animationElement.origin !== "custom" ||
                        animationElement.isAdded
                    ).length <= 1)) && (
                  <div className="text-center add-more-anm-face">
                    <img
                      src={getImageUrl("empty-animations.png")}
                      alt=""
                      style={{ opacity: 0.3 }}
                    />
                    <Typography className="mt-3 mb-0" align="center">
                      <span
                        style={{
                          cursor: "pointer",
                          textTransform: "uppercase",
                          letterSpacing: 3,
                          color: "#7cd959",
                        }}
                        onClick={() => navigate("/marketplace")}
                      >
                        + Add animations here
                      </span>
                    </Typography>
                  </div>
                )}
              <div className="row">
                {animationButtons?.length > 0 &&
                  !loading &&
                  animationButtons
                    .filter(
                      (animationElement) =>
                        animationElement.origin !== "custom" ||
                        animationElement.isAdded
                    )
                    .map((button, index) => (
                      <div className="col-lg-6">
                        {button.type === "faceAnim" ? (
                          <FaceAnimationButton
                            overlayTitle={button.title}
                            custom={button.origin !== "marketplace"}
                            targetOriginScene={targetOriginScene}
                            modelUrl={baseUrl + button.rootPath + "model.glb"}
                            thumbnail={
                              baseUrl + button.rootPath + "thumbnail.jpg"
                            }
                          />
                        ) : button.type === "videoAnim" &&
                          button.config &&
                          "background" in button.config ? (
                          <OverlayButton
                            overlayTitle={button.title}
                            custom={button.origin !== "marketplace"}
                            thumbnail={
                              baseUrl + button.rootPath + "thumbnail.jpg"
                            }
                            backgroundUrl={
                              button.config.background
                                ? baseUrl + button.rootPath + "background.webm"
                                : ""
                            }
                            foregroundUrl={
                              button.config.foreground
                                ? baseUrl + button.rootPath + "foreground.webm"
                                : ""
                            }
                            targetOriginScene={targetOriginScene}
                            duration={button.duration}
                          />
                        ) : null}
                      </div>
                    ))}
              </div>
            </ARStudioInsiderWrapper>
          </div>
        </ARStudioInsider>
        <ARStudioInsider>
          <div className="card-overlay-box p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h3 className="common-title mb-0">Faces</h3>
              {(faceButtons?.length > 1 ||
                (faceButtons?.length > 0 &&
                  !loading &&
                  faceButtons.filter(
                    (faceElement) =>
                      faceElement.origin !== "custom" || faceElement.isAdded
                  ).length > 1)) && (
                <span
                  style={{
                    cursor: "pointer",
                    textTransform: "uppercase",
                    letterSpacing: 2,
                    color: "#7cd959",
                  }}
                  onClick={() => navigate("/marketplace")}
                >
                  + Add More
                </span>
              )}
            </div>
            <ARStudioInsiderWrapper className={"play_overlays"}>
              {loading && (
                <div className="add-more-anm-face">
                  <Typography align="center">
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  </Typography>
                </div>
              )}
              {!loading &&
                (faceButtons?.length <= 1 ||
                  (faceButtons?.length > 0 &&
                    !loading &&
                    faceButtons.filter(
                      (faceElement) =>
                        faceElement.origin !== "custom" || faceElement.isAdded
                    ).length <= 1)) && (
                  <div className="text-center add-more-anm-face">
                    <svg
                      style={{ opacity: 0.3 }}
                      width="80"
                      height="80"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.16998 9.335C7.29398 9.088 7.75698 8.75 8.49998 8.75C9.24298 8.75 9.70598 9.088 9.82998 9.335C9.87088 9.42802 9.93025 9.51176 10.0045 9.58114C10.0787 9.65052 10.1663 9.70409 10.2619 9.7386C10.3574 9.7731 10.459 9.78783 10.5605 9.78188C10.6619 9.77593 10.7611 9.74943 10.852 9.70399C10.9429 9.65854 11.0236 9.59511 11.0892 9.51752C11.1548 9.43994 11.204 9.34983 11.2337 9.25267C11.2635 9.15551 11.2731 9.05331 11.2622 8.95229C11.2512 8.85127 11.2199 8.75353 11.17 8.665C10.713 7.747 9.60398 7.25 8.49998 7.25C7.39598 7.25 6.28798 7.747 5.82998 8.665C5.7801 8.75353 5.74873 8.85127 5.73777 8.95229C5.72681 9.05331 5.7365 9.15551 5.76624 9.25267C5.79598 9.34983 5.84515 9.43994 5.91078 9.51752C5.9764 9.59511 6.0571 9.65854 6.14799 9.70399C6.23887 9.74943 6.33805 9.77593 6.43949 9.78188C6.54093 9.78783 6.64252 9.7731 6.73809 9.7386C6.83367 9.70409 6.92123 9.65052 6.99548 9.58114C7.06972 9.51176 7.12909 9.42802 7.16998 9.335ZM15.5 8.75C14.757 8.75 14.294 9.088 14.17 9.335C14.0755 9.5027 13.9201 9.62766 13.7361 9.68399C13.552 9.74033 13.3533 9.72374 13.1812 9.63765C13.009 9.55157 12.8765 9.40258 12.8111 9.22153C12.7458 9.04047 12.7525 8.84122 12.83 8.665C13.288 7.747 14.396 7.25 15.5 7.25C16.604 7.25 17.712 7.747 18.17 8.665C18.2199 8.75353 18.2512 8.85127 18.2622 8.95229C18.2731 9.05331 18.2635 9.15551 18.2337 9.25267C18.204 9.34983 18.1548 9.43994 18.0892 9.51752C18.0236 9.59511 17.9429 9.65854 17.852 9.70399C17.7611 9.74943 17.6619 9.77593 17.5605 9.78188C17.459 9.78783 17.3574 9.7731 17.2619 9.7386C17.1663 9.70409 17.0787 9.65052 17.0045 9.58114C16.9302 9.51176 16.8709 9.42802 16.83 9.335C16.706 9.088 16.243 8.75 15.5 8.75Z"
                        fill="white"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.835 2.21199L7.725 2.02699C6.692 1.85499 5.837 1.71199 5.155 1.69599C4.439 1.67899 3.776 1.79399 3.222 2.26299C2.669 2.73099 2.446 3.36699 2.346 4.07499C2.25 4.74999 2.25 5.615 2.25 6.66199V12C2.25 17.964 6.874 20.814 9.605 21.976L9.632 21.988C9.972 22.132 10.29 22.268 10.656 22.359C11.042 22.455 11.452 22.496 12 22.496C12.548 22.496 12.958 22.456 13.344 22.359C13.71 22.268 14.028 22.132 14.368 21.987L14.395 21.977C17.126 20.814 21.75 17.964 21.75 12V6.66199C21.75 5.615 21.75 4.74999 21.654 4.07499C21.554 3.36599 21.331 2.73099 20.778 2.26299C20.224 1.79299 19.561 1.67899 18.845 1.69599C18.163 1.71199 17.308 1.85499 16.275 2.02699L15.165 2.21199C13.501 2.48899 12.751 2.61199 12 2.61199C11.249 2.61199 10.499 2.48899 8.835 2.21199ZM4.192 3.40699C4.346 3.27699 4.576 3.18199 5.119 3.19599C5.682 3.20899 6.431 3.33199 7.534 3.51599L8.662 3.70399C10.232 3.96599 11.11 4.11199 12 4.11199C12.89 4.11199 13.767 3.96599 15.338 3.70399L16.466 3.51599C17.569 3.33199 18.318 3.20899 18.881 3.19599C19.424 3.18199 19.654 3.27599 19.808 3.40699C19.962 3.53699 20.093 3.74899 20.169 4.28599C20.249 4.84299 20.25 5.60099 20.25 6.71899V12C20.25 17.017 16.396 19.495 13.808 20.596C13.437 20.754 13.221 20.844 12.981 20.904C12.751 20.961 12.471 20.996 12 20.996C11.529 20.996 11.249 20.961 11.019 20.904C10.779 20.844 10.563 20.754 10.192 20.596C7.604 19.495 3.75 17.016 3.75 12V6.71899C3.75 5.60099 3.752 4.84299 3.831 4.28599C3.907 3.74899 4.038 3.53699 4.192 3.40699Z"
                        fill="white"
                      />
                      <path
                        d="M7.95694 13.483C7.81996 13.6278 7.74599 13.821 7.75124 14.0203C7.75649 14.2196 7.84053 14.4086 7.98494 14.546L8.01494 14.573L8.07494 14.623C8.12494 14.663 8.19194 14.716 8.27794 14.775C8.45094 14.893 8.69994 15.041 9.02894 15.187C9.69194 15.477 10.6689 15.75 11.9999 15.75C13.3299 15.75 14.3079 15.478 14.9699 15.187C15.2999 15.041 15.5499 14.893 15.7219 14.775C15.8129 14.7125 15.9003 14.645 15.9839 14.573L16.0039 14.555L16.0119 14.549L16.0149 14.546L16.0159 14.544C16.1612 14.4079 16.2474 14.2198 16.2538 14.0209C16.2603 13.822 16.1875 13.6287 16.0514 13.4835C15.9154 13.3383 15.7272 13.2531 15.5283 13.2466C15.3295 13.2401 15.1362 13.3129 14.9909 13.449L14.9849 13.454L14.9749 13.462C14.9423 13.4887 14.9086 13.514 14.8739 13.538C14.7729 13.607 14.6059 13.708 14.3669 13.813C13.8919 14.023 13.1199 14.25 11.9999 14.25C10.8799 14.25 10.1079 14.022 9.63294 13.813C9.42471 13.7231 9.22687 13.6107 9.04294 13.478C9.03653 13.4728 9.0302 13.4674 9.02394 13.462L9.00994 13.45C8.86532 13.316 8.67375 13.2443 8.47667 13.2505C8.2796 13.2567 8.09289 13.3402 7.95694 13.483Z"
                        fill="white"
                      />
                    </svg>
                    <Typography className="mt-3 mb-0" align="center">
                      <span
                        style={{
                          cursor: "pointer",
                          textTransform: "uppercase",
                          letterSpacing: 3,
                          color: "#7cd959",
                        }}
                        onClick={() => navigate("/marketplace")}
                      >
                        + Add faces here
                      </span>
                    </Typography>
                  </div>
                )}
              <div className="row">
                {faceButtons?.length > 0 &&
                  !loading &&
                  faceButtons
                    .filter(
                      (faceElement) =>
                        faceElement.origin !== "custom" || faceElement.isAdded
                    )
                    .map((button, index) => {
                      // Determine which component to use based on the button type
                      const isFaceMeshButton = button.type === "faceMesh";
                      const commonProps = {
                        overlayTitle: button.title,
                        custom: button.origin !== "marketplace",
                        thumbnail: baseUrl + button.rootPath + "thumbnail.jpg",
                        avatarId: button._id,
                        activeAvatar: activeAvatar,
                        setActiveAvatar: setActiveAvatar,
                      };

                      return (
                        <div className="col-lg-6">
                          {isFaceMeshButton ? (
                            <FaceMeshButton
                              {...commonProps}
                              textureUrl={
                                baseUrl + button.rootPath + "texture.png"
                              }
                              isSettingsSet={isSettingsSet}
                            />
                          ) : (
                            <FaceAvatarButton
                              {...commonProps}
                              assetUrl={baseUrl + button.rootPath + "model.glb"}
                              isSettingsSet={isSettingsSet}
                            />
                          )}
                        </div>
                      );
                    })}
              </div>
            </ARStudioInsiderWrapper>
          </div>
        </ARStudioInsider>
        <InformativeWrapper>
          <div className="card-overlay-box p-4">
            <div className="informative_flex">
              <div className="left_discover">
                <img src={getImageUrl("fortnite-demo.jpg")} />
                <Button
                  onClick={() => navigate("/marketplace")}
                  text="Discover"
                  type="button"
                  className="global_button inline_anchor-button mt-4 bold"
                  style={{
                    background: "rgba(123, 217, 89, 0.16)",
                    borderRadius: 50,
                  }}
                />
              </div>
              <div className="right_video">
                <div className="setup_info">
                  <div>
                    <Typography className="tagline">
                      <svg
                        style={{ verticalAlign: "middle", marginRight: 10 }}
                        width="40"
                        height="40"
                        viewBox="0 0 30 27"
                        fill="none"
                      >
                        <g opacity="0.7">
                          <path
                            d="M26.2501 4.88251C26.0626 4.74845 25.8431 4.65532 25.6089 4.61043C25.3747 4.56554 25.1321 4.5701 24.9001 4.62376L16.2501 6.62626V22.68L25.7001 20.4975C26.0381 20.4196 26.3374 20.2422 26.5504 19.9935C26.7633 19.7447 26.8776 19.439 26.8751 19.125V5.98501C26.8713 5.77051 26.813 5.55965 26.7046 5.36855C26.5963 5.17746 26.4408 5.0112 26.2501 4.88251Z"
                            fill="white"
                          />
                          <path
                            d="M13.75 6.62624L5.07502 4.62374C4.84664 4.57615 4.60938 4.57467 4.38028 4.6194C4.15119 4.66414 3.93595 4.75399 3.75002 4.88249C3.56056 5.01581 3.40784 5.18668 3.30377 5.38181C3.1997 5.57694 3.14709 5.79106 3.15002 6.00749V19.125C3.15028 19.4371 3.26588 19.7402 3.47861 19.9866C3.69133 20.2331 3.9891 20.4088 4.32502 20.4862L13.75 22.68V6.62624Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                      Ready to bring your stream to life?
                    </Typography>
                    <Typography className="setup-text">
                      <span>2MIN </span> SETUP
                    </Typography>
                    <div className="small-text">STRAIGHT TO THE POINT</div>
                  </div>
                </div>
                <div className="quickstart_video">
                  <Player
                    playsInline
                    src={`${statics.CLOUDFRONT_URL}/app-assets/videos/quick-start.webm`}
                  />
                </div>
              </div>
            </div>
          </div>
        </InformativeWrapper>
      </ARStudioWrappper>
    </ARStudioMain>
  );
};

export default ARStudio;
