import axios from "axios";
import { cleanLocalStorage } from "./utils/general";
import Cookies from "js-cookie";
import { statics } from "./utils/statics";
import { apiUrl } from "./utils/getPaths";
import { endPoints } from "./utils/apiEndPoints";

const accessTokenRefreshFunction = async () => {
  try {
    const refreshToken = Cookies.get("refreshToken");
    // Make an API call to refresh the access token using the refresh token
    const response = await axios.post(apiUrl(endPoints.refreshToken), {
      token: refreshToken,
    });

    return response.data;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null; // Return null or handle the error accordingly
  }
};

const axiosInstance = axios.create({
  baseURL: statics.BASE_URL,
});

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    const user = localStorage.getItem("userinfo");
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`; // Add the token to the Authorization header
    }
    if (!accessToken || !user) {
      const cookies = Object.keys(Cookies.get());
      cookies.forEach((cookieName) => {
        Cookies.remove(cookieName);
      });
      cleanLocalStorage();
      window.location.href = "/";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newTokenRes = await accessTokenRefreshFunction();
      if (newTokenRes?.accessToken && newTokenRes?.refreshToken) {
        const expirationTime = new Date();
        Cookies.set("tokenExpiration", JSON.stringify(expirationTime));
        Cookies.set("accessToken", newTokenRes?.accessToken, {
          expires: expirationTime.setTime(
            expirationTime.getTime() + 15 * 24 * 60 * 60 * 1000
          ),
        }); // Expires in 15 days
        Cookies.set("refreshToken", newTokenRes?.refreshToken, {
          expires: expirationTime.setTime(
            expirationTime.getTime() + 30 * 24 * 60 * 60 * 1000
          ),
        }); // Expires in 30 days
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${newTokenRes?.accessToken}`;
        return axiosInstance(originalRequest);
      } else {
        const cookies = Object.keys(Cookies.get());
        cookies.forEach((cookieName) => {
          Cookies.remove(cookieName);
        });
        cleanLocalStorage();
        window.location.href = "/";
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
