import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { CSSProperties } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@mui/material";

interface Loader {
  isThere: boolean;
  loaderValue: boolean;
}

interface ButtonProps {
  text: string | React.ReactNode;
  type: "submit" | "button" | "reset" | undefined;
  onClick: (e: React.SyntheticEvent) => void;
  className?: string;
  disabled?: boolean;
  loader?: Loader;
  style?: CSSProperties;
  title?: string;
  image?: string;
  imageStyle?: React.CSSProperties;
  buttonAnimation?: {
    animation: boolean;
    duration: number;
  };
  icon?: any;
  draggable?: boolean; // New prop
  onDragStart?: (e: React.DragEvent) => void; // New prop
  buttonBorderRadius?: number | string;
}

const Button = ({
  text,
  type,
  onClick,
  className,
  disabled,
  style,
  loader,
  image,
  imageStyle,
  buttonAnimation,
  draggable,
  icon,
  onDragStart,
  buttonBorderRadius,
  ...other
}: ButtonProps) => {
  const fadeInAnimation = keyframes`
  0% {
    width: 0
  }
  100% {
    width: 100%
  }
`;

  const StyledDiv = styled("div")`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: ${buttonBorderRadius ? buttonBorderRadius : "8"}px;
    z-index: 0 !important;
    background: ${other.title === "Play Random Alert"
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(70, 105, 17, 0.58)"};
    animation: ${fadeInAnimation} ${buttonAnimation?.duration}s ease-in-out;
  `;

  return (
    <button
      disabled={disabled}
      style={
        disabled
          ? { ...style, opacity: 0.5, pointerEvents: "none" }
          : { ...style, opacity: 1 }
      }
      className={className}
      {...other}
      type={type}
      onClick={onClick}
      draggable={draggable} // Added draggable prop
      onDragStart={onDragStart} // Added onDragStart prop
    >
      {buttonAnimation && buttonAnimation.animation === true ? (
        <StyledDiv />
      ) : (
        ""
      )}
      {image && (
        <img
          src={image}
          alt="Icon"
          style={{ ...imageStyle, marginRight: 15 }}
        />
      )}
      {icon && <>{icon}</>}
      {text}
      {loader?.isThere && loader.loaderValue === true && (
        <CircularProgress size={16} style={{ color: "#fff", marginLeft: 10 }} />
      )}
    </button>
  );
};

export default Button;
