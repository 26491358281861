import { Link } from "react-router-dom";
import GeneralTips from "../components/help/GeneralTips";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { AlertBox } from "../components/dashboard/AlertBox";
import Steps from "../components/help/Steps";


const Help = () => {
    return (
        <div className="help">
            <div className="d-flex align-items-center">
                <h3 className="common-title">How does it work?</h3>
                <div className="ml-auto mb-4 d-flex align-items-center">
                    <Link to="/dashboard" className="custom-alert-button">
                      Go to Dashboard
                    </Link>
                </div> 
            </div>
            <div className="card-overlay-box p-4">
                <div className="d-flex justify-content-around">
                    <Steps />
                    <AlertBox override={true} height={720} width={1280} />
                </div>
            </div>
            <div className="card-overlay-box p-4">
                <h6 className="custom-alert-bold">
                    <TipsAndUpdatesIcon />  GENERAL TIPS:</h6>
                <GeneralTips />
            </div>
        </div>
    );
};

export default Help;