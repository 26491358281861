import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { getImageUrl, getUserInfo } from "../utils/getPaths";
import { useSelector } from "react-redux";
import { WebcamContext } from "../utils/context";
import { Typography } from "@mui/material";

const Header = () => {
  const { showBurgerMenu, setshowBurgerMenu }: any = useContext(WebcamContext);
  const { loggedInUser } = useSelector((state: any) => state.authentication);

  const handleShowSideBarMenu = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setshowBurgerMenu(!showBurgerMenu);
  };

  return (
    <div
      className={`app_header-main d-flex justify-content-between align-items-center ${
        showBurgerMenu ? "menuOpen" : ""
      }`}
    >
      <div className="main_app-logo">
        <Link to="/dashboard">
          <img
            alt="app-logo"
            className="app-logo-after"
            src={getImageUrl("logo-png.png")}
          />
          {/* <span>BETA</span> */}
        </Link>
      </div>
      <div
        className="stream_indicator"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography className="mr-3" style={{ fontSize: 14 }}>
          Hey,{" "}
          <span style={{ fontWeight: 700, color: "#7CD959" }}>
            {getUserInfo()?.userName}
          </span>
          !
        </Typography>
        <Link to="/profile" className="goto-profile">
          <div>
            <img
              src={
                loggedInUser?.profilePicture ||
                getImageUrl("user-dummy-img.png")
              }
              alt="user-img"
            />
          </div>
        </Link>
        {window.innerWidth < 768 && (
          <button
            className={
              showBurgerMenu ? "hamburger_menu close" : "hamburger_menu"
            }
            onClick={(e: React.SyntheticEvent) => handleShowSideBarMenu(e)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
