import { useState, useEffect } from "react";
import Button from "../formComponents/Button";
import { getToaster } from "../../utils/general";
import axiosInstance from "../../axios.instance";
import { apiUrl, getImageUrl, getUserInfo } from "../../utils/getPaths";
import { endPoints } from "../../utils/apiEndPoints";
import SwitchComponent from "../formComponents/Switch";

interface FaceAvatarButtonProps {
  overlayTitle: string;
  custom?: boolean;
  thumbnail: string;
  assetUrl: string;
  avatarId: string;
  activeAvatar: {
    avatarId: string;
    assetUrl: string;
  };
  setActiveAvatar: any;
  isSettingsSet: boolean;
}

const FaceAvatarButton = ({
  overlayTitle,
  custom,
  thumbnail,
  assetUrl,
  avatarId,
  activeAvatar,
  setActiveAvatar,
  isSettingsSet,
}: FaceAvatarButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(activeAvatar.avatarId === avatarId);
  }, [activeAvatar]);

  const ButtonText = (buttonName: string) => {
    return (
      <>
        <span>
          {custom && buttonName.split("&")[0].length > 16
            ? `${buttonName.split("&")[0].slice(0, 15)}...`
            : custom && buttonName.split("&")[0].length <= 16
            ? buttonName.split("&")[0]
            : ""}
          {!custom && buttonName.length > 16
            ? `${buttonName.slice(0, 15)}...`
            : !custom && buttonName.length <= 16
            ? buttonName
            : ""}
        </span>
        <SwitchComponent
          checked={isActive}
          onChange={isActive ? handleDisableAvatar : handleSet3DAvatars}
          className="ml-auto"
        />
      </>
    );
  };

  const handleDisableAvatar = async () => {
    await axiosInstance
      .put(`${apiUrl(endPoints.settings)}/${getUserInfo()?.twitchId}`, {
        settings: {
          activeAvatar: {
            avatarId: "",
            assetUrl: "",
          },
        },
        sendEffect: {
          endPoint: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          typeOfEvent: "facesDisabled",
        },
      })
      .then((res) => {
        if (res.data) {
          setActiveAvatar({
            avatarId: "",
            assetUrl: "",
          });
          getToaster("success", `${overlayTitle} is now deactivated.`);
        }
      })
      .catch((err) => {
        getToaster("error", "Error while deactivating face. Please try again.");
        throw err;
      });
  };

  const handleSet3DAvatars = async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (!isSettingsSet) {
      getToaster(
        "warn",
        "Please select your camera from camera settings first."
      );
      return;
    }
    let avatarIdToSend = "";
    let urlToSend = "";
    if (!isActive) {
      setIsActive(true);
      avatarIdToSend = avatarId;
      urlToSend = assetUrl;
    } else {
      setIsActive(false);
    }

    await axiosInstance
      .put(`${apiUrl(endPoints.settings)}/${getUserInfo()?.twitchId}`, {
        settings: {
          activeAvatar: {
            avatarId: avatarIdToSend,
            assetUrl: urlToSend,
          },
        },
        sendEffect: {
          endPoint: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          typeOfEvent: "avatarsSettingsChanged",
          params: {
            facemaskTitle: overlayTitle,
            newModelUrl: urlToSend,
          },
        },
      })
      .then((res) => {
        setActiveAvatar({
          avatarId: avatarIdToSend,
          assetUrl: urlToSend,
        });
        setLoading(false);
        getToaster("success", `${overlayTitle} is now active face.`);
      })
      .catch((err) => {
        setLoading(false);
        getToaster("error", "Error while activating face. Please try again.");
      });
  };

  return (
    <>
      <Button
        className={custom ? "custom_alert-button" : ""}
        title={"Play face avatar"}
        text={ButtonText(overlayTitle)}
        type="button"
        onClick={(e) => {}}
        style={{
          position: "relative",
          padding: "17px 10px 17px 85px",
        }}
        image={thumbnail ? thumbnail : getImageUrl("streamfog_placeholder.png")}
        imageStyle={{
          maxWidth: `${!thumbnail ? "60px" : ""}`,
          objectFit: `${!thumbnail ? "contain" : "cover"}`,
          background: `${!thumbnail ? "#1a1d1f" : ""}`,
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "8px 0 0 8px",
        }}
      />
    </>
  );
};

export default FaceAvatarButton;
