import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface DashboardStates {
  temporaryOverlayImages: any[];
  isStreaming: boolean;
  marketPlaceOverlays: any[];
  reduxCustomOverlays: any[];
  whichScheduledAlertsAreRunning: any[];
  currentPlayingAlert: string;
}

const initialState: DashboardStates = {
  temporaryOverlayImages: [],
  isStreaming: false,
  marketPlaceOverlays: [],
  whichScheduledAlertsAreRunning: [],
  reduxCustomOverlays: [],
  currentPlayingAlert: "",
};

export const store = (images: any) => (dispatch: any) => {
  dispatch(storeTempOverlayImages(images));
  return true;
};

export const dashboardSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    storeTempOverlayImages: (state, action) => {
      state.temporaryOverlayImages = action.payload;
    },
    setIsStreaming: (state, action) => {
      state.isStreaming = action.payload;
    },
    storeMarketPlaceItems: (state, action) => {
      const myPayload = action.payload;
      state.marketPlaceOverlays.push(myPayload);
    },
    storeCustomOverlayItems: (state, action) => {
      const myPayload = action.payload;
      state.reduxCustomOverlays.push(myPayload);
    },
    setCustomOverlayAfterDeletion: (state, action) => {
      state.reduxCustomOverlays = action.payload;
    },
    storeScheduledAlertsAreRunning: (state, action) => {
      state.whichScheduledAlertsAreRunning.push(action.payload);
    },
    storeCurrentRunningAlert: (state, action) => {
      state.currentPlayingAlert = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeTempOverlayImages,
  setIsStreaming,
  storeMarketPlaceItems,
  storeScheduledAlertsAreRunning,
  setCustomOverlayAfterDeletion,
  storeCustomOverlayItems,
  storeCurrentRunningAlert,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
