import React from "react";
import { Search } from "@mui/icons-material";

interface InputProps {
  type: string;
  placeholder?: string;
  label?: string;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  className?: string;
  wrapperClass?: string;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
}

const Input = ({
  label,
  type,
  name,
  placeholder,
  value,
  onChange,
  className,
  wrapperClass,
  disabled,
  required,
  maxLength,
  ...other
}: InputProps) => {
  return (
    <div className={`global_inputfield ${wrapperClass}`}>
      {label && label !== "" && (
        <label>
          {label}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}
      {type === "search" && <Search className="search_icon" />}
      <input
        maxLength={maxLength}
        disabled={disabled}
        type={type}
        name={name}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...other}
      />
    </div>
  );
};

export default Input;
