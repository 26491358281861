import { Switch } from "@mui/material";
import React from "react";

interface SwitchProps {
  checked: boolean;
  onChange: (e: any) => void;
  disabled?: boolean;
  label?: string;
  className?: string;
  labelDirection?: string;
}

const SwitchComponent = ({
  checked,
  onChange,
  disabled,
  label,
  className,
  labelDirection,
}: SwitchProps) => {
  return (
    <div className={`switch ${className}`}>
      {labelDirection === "left" && <span>{label}</span>}
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={labelDirection === "left" ? "ml-2" : "mr-2"}
      />
      {labelDirection !== "left" && <span>{label}</span>}
    </div>
  );
};

export default SwitchComponent;
