import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/slices/authenticationSlice";
import dashboardReducer from "../redux/slices/dahsboardSlice";
import paymentReducer from "./slices/paymentSlice";

export const store = configureStore({
  reducer: {
    authentication: authReducer,
    dashboard: dashboardReducer,
    payment: paymentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
