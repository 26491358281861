export const endPoints = {
  twitchUserRegistration: "/users/twitch-register",
  getWhiteListedUsers: "/users/white-listed",
  saveOverlay: "/overlays",
  getOverlay: "/overlays",
  settings: "/settings",
  createPaymentIntent: "/payments/create-payment-intent",
  getCountries: "/payments/get-countries",
  getStates: "/payments/get-states",
  getCities: "/payments/get-cities",
  getAllSubscriptions: "/payments",
  createSubscription: "/payments/create-subscription",
  getCovertedCurrency: "/payments/get-currency-converted",
  addPaymentDetailsToUser: "/payments/add-payment-detail",
  createPaymentMethod: "/payments/create-payment-method",
  attachPaymentMethod: "/payments/attach-payment-method",
  makePaymentMethodDefault: "/payments/make-default-pm",
  getListOfPaymentMethods: "/payments/list-payment-methods",
  getStripeCustomerDetails: "/payments/get-stripe-customer",
  getPaymentMethodDetail: "/payments/payment-method",
  editUser: "/users/edit-user",
  addFreeTrial: "/users/add-free-trial",
  getUser: "/users/get-user",
  detachCard: "/payments/detach-payment-method",
  storeSignedUrlOfMyOverlays: "/downloadedOverlays",
  getSignedUrlOfMyOverlays: "/downloadedOverlays",
  deleteSignedUrlOfMyOverlays: "/downloadedOverlays/delete",
  setScheduledAlerts: "/settings/scheduledAlerts",
  sendPlayAlertAkg: "/sendData",
  sendDataFromRenderingCanvas: "/sendDataFromRenderingCanvas",
  remoteLogin: "/users/remote-login",
  deleteAllOverlaysList: "/downloadedOverlays/deleteAllOverlays",
  getMarketplaceOverlays: "/downloadedOverlays/marketplace",
  getAwsData: "/downloadedOverlays/marketplace/awsData",
  addOverlayToDB: "/downloadedOverlays/marketplace/addOverlay",
  deleteOverlayFromS3: "/downloadedOverlays/marketplace/custom/delete",
  uploadFileToS3: "/downloadedOverlays/marketplace/custom/upload",
  refreshToken: "/users/refresh-token",
  storeUrlsOfVirtualScene: "/virtualScenes",
  storeTransitionData: "/transitions",
  deleteSignedUrlOfMyOverlaysExternal: "/downloadedOverlays/delete/external",
  deleteUrlsOfVirtualScene: "/virtualScenes/delete",
  getVirtualScenes: "/virtualScenes",
  updateSettings: "/settings/scheduledAlerts",
  getAppAccessToken: "/users/app-access-token",
  verifySignatureElgato: "/verify-signature-elgato",
  loginWithJWT: "/users/app-login",
  inventory: "/user-content/inventory",
  assets: "/user-content/assets",
  marketplaceFaces: "/marketplaceFaces",
  marketplaceAnims: "/marketplaceAnims",
  getGames: "/games",
  createFacemeshFromAi: "/ai-cv/create-facemesh",
  createFacemeshFromImg: "/ai-cv/create-texture-from-img",
};
