import { toast } from "react-toast";
import axiosInstance from "../axios.instance";
import { apiUrl, getUserInfo } from "./getPaths";
import { endPoints } from "./apiEndPoints";
import AWS, { AWSError } from "aws-sdk";

export const returnUploadPromise = async (
  folderPath: string,
  filename: string,
  fileContent: any
) => {
  return new Promise((resolve, reject) => {
    const params: any = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `${folderPath}${filename}`,
      Body: fileContent,
      ContentType: filename === "meta.json" ? "application/json" : "",
    };
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_CUTE_KEY,
      secretAccessKey: process.env.REACT_APP_ANGRY_KEY,
      region: "us-east-1",
    });
    s3.upload(params, (err: any, data: any) => {
      if (err) {
        console.error("Error uploading file:", err);
        getToaster(
          "error",
          `Error while uploading. Please select ${filename} again & try.`
        );
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
};

export const cleanLocalStorage = () => {
  localStorage.removeItem("userinfo");
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("sub_access_token");
  localStorage.removeItem("my_user_id");
  localStorage.removeItem("broadcaster_id");
  localStorage.removeItem("session_id");
  localStorage.removeItem("isStreaming");
  localStorage.removeItem("channel.events");
  localStorage.removeItem("eventsAndItsAnimations");
  // localStorage.removeItem("localsystemfolders");
  localStorage.removeItem("expressStarted");
  localStorage.removeItem("freeTrialEndsAt");
  localStorage.removeItem("isFreeTrialRunning");
  localStorage.removeItem("setFreeTrialEndedPopupTimer");
  localStorage.removeItem("stripeCustomerId");
  localStorage.removeItem("newUserRegistered");
  localStorage.removeItem("appAccessToken");
  localStorage.removeItem("twitchWebhhoksSubscribed");
};

export interface StreamFogPlansProps {
  name: string;
  price: string;
  benefits: any[];
  price_id?: string;
}

export const camelCaseToNormalCase = (word: string) => {
  const result = word && word.replace(/([A-Z])/g, "$1");
  const finalResult =
    result && result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const getToaster = (
  type: "success" | "error" | "warn",
  message: string,
  options?: any
) => {
  if (type === "success") {
    return toast.success(message, {
      backgroundColor: "#6f9b2a",
      color: "#ffffff",
    });
  } else if (type === "error") {
    return toast.error(message, {
      backgroundColor: "#dd2a2a",
      color: "#ffffff",
    });
  } else if (type === "warn") {
    return toast.warn(message, {
      backgroundColor: "#db920a",
      color: "#ffffff",
    });
  } else {
    toast(message);
  }
};

export const sendInstantEffect = async (typeOfEvent: string, params?: any) => {
  try {
    const res = await axiosInstance.post(apiUrl(endPoints.sendPlayAlertAkg), {
      userID: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
      data: {
        typeOfEvent,
        params,
      },
    });
    return res.data;
  } catch (error) {
    getToaster("error", "Error while sending instant effects to the stream.");
    throw error;
  }
};
