import React from "react";
import { getImageUrl } from "../utils/getPaths";
import Login from "../modules/BeforeLogin/Login";
import { Navigate } from "react-router-dom";

const BeforeLogin = ({ token, children }: any) => {
  return (
    <div className="authentication_main">
      <img
        className="header_app_logo"
        src={getImageUrl("app-logo-with-name.svg")}
        alt="app-logo"
      />
      {children}
    </div>
  );
};

export default BeforeLogin;
