import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import LensBlurRoundedIcon from "@mui/icons-material/LensBlurRounded";
import HighlightRoundedIcon from "@mui/icons-material/HighlightRounded";
import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";

const GeneralTips = () => {
  const codeSnippet = `https://obsproject.com/kb/launch-parameters`;

  return (
    <List component="nav">
      <ListItemButton>
        <ListItemIcon>
          <CheckBoxRoundedIcon />
        </ListItemIcon>
        <ListItemText
          secondary={
            <p>
              Make sure you start{" "}
              <b className="custom-alert-bold">
                OBS AFTER Streamfog but BEFORE
              </b>{" "}
              you start the camera in Streamfog
            </p>
          }
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <LensBlurRoundedIcon />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              If the <b className="custom-alert-bold">camera looks blurry</b>,
              add the following <b className="custom-alert-bold">tags</b> to
              your desktop shortcut by following this description:
              <br />
              <a
                href={codeSnippet}
                target="_blank"
                className="custom-alert-bold"
              >
                OBS Launch Parameters
              </a>
            </>
          }
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <HighlightRoundedIcon />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              If using the{" "}
              <b className="custom-alert-bold">Spotlight feature</b>, make sure{" "}
              <b className="custom-alert-bold">the Browser</b> source has{" "}
              <b className="custom-alert-bold">ADVANCED</b> access to{" "}
              <b className="custom-alert-bold">OBS</b> in the settings of the
              source
            </>
          }
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <ComputerRoundedIcon />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              Use the <b className="custom-alert-bold">SAME Browser</b> source
              across scenes.
            </>
          }
        />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <HourglassBottomRoundedIcon />
        </ListItemIcon>
        <ListItemText
          secondary={
            <>
              If the camera picture looks delayed,{" "}
              <b className="custom-alert-bold">give it a couple seconds</b>, it
              should stabilize.
            </>
          }
        />
      </ListItemButton>
    </List>
  );
};

export default GeneralTips;
