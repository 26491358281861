export const statics = {
  BASE_URL: process.env.REACT_APP_API_STAGGING_URL,
  BASE_URL_V2: process.env.REACT_APP_API_STAGGING_URL_V2,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  BASE_SEGMENTATION_URL: process.env.REACT_APP_SEGMENTATION_URL,
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
  IMAGE_URL: process.env.REACT_APP_IMAGE_URL,
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL,
  STREAMING_URL: process.env.REACT_APP_STREAMING_URL,
  CLOUDFRONT_URL: process.env.REACT_APP_CLOUDFRONT_URL,
};
