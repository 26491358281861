import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { getImageUrl, getUserInfo } from "../utils/getPaths";
import { WebcamContext } from "../utils/context";
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { setshowBurgerMenu, sidebarCollapsed }: any =
    useContext(WebcamContext);
  const [isSettings, setIsSettings] = useState(false);
  const { loggedInUser } = useSelector((state: any) => state.authentication);

  return (
    <div className="app-sidebar-main">
      <ul className="upper-menu sidebar-menu">
        {window.innerWidth >= 768 && (
          <>
            <li>
              <div
                className={
                  sidebarCollapsed ? "main_app-logo px-2 py-3" : "main_app-logo"
                }
              >
                <Link to="/dashboard" className="pl-0 m-0">
                  <img
                    alt="app-logo"
                    className="app-logo-after"
                    src={
                      sidebarCollapsed
                        ? getImageUrl("streamfog_placeholder.png")
                        : getImageUrl("logo-png.png")
                    }
                  />
                  {/* <span>BETA</span> */}
                </Link>
              </div>
            </li>
            <li>
              <NavLink
                className={(navData) => {
                  if (navData.isActive) {
                    document.title = "AR Studio | Streamfog";
                  }
                  return navData.isActive
                    ? "goto-profile px-0 pb-4 active"
                    : "goto-profile px-0 pb-4";
                }}
                to="/profile"
              >
                <img
                  src={
                    loggedInUser?.profilePicture ||
                    getImageUrl("user-dummy-img.png")
                  }
                  alt="user-img"
                />
                <Typography
                  className="mt-3 menu-name"
                  style={{ fontSize: 14, letterSpacing: 0.5, fontWeight: 700 }}
                >
                  {getUserInfo()?.userName}
                </Typography>
              </NavLink>
            </li>
          </>
        )}
        <li>
          <NavLink
            className={(navData) => {
              if (navData.isActive) {
                document.title = "AR Studio | Streamfog";
              }
              return navData.isActive ? "active" : "";
            }}
            to="/ar-studio"
            onClick={() => {
              setshowBurgerMenu(false);
            }}
          >
            <svg height="24px" width="24px" viewBox="0 0 512 512">
              <g>
                <g>
                  <polygon points="26.624,142.166 26.624,409.6 231.424,512 231.424,244.564 		" />
                </g>
              </g>
              <g>
                <g>
                  <polygon points="256,0 54.102,100.95 256,201.898 457.898,100.95 		" />
                </g>
              </g>
              <g>
                <g>
                  <polygon points="280.576,244.564 280.576,512 485.376,409.6 485.376,142.166 		" />
                </g>
              </g>
            </svg>
            <span className="menu-name">My AR Studio</span>
          </NavLink>
        </li>
      </ul>
      <ul className="lower-menu sidebar-menu">
        <li>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2.5,
              pb: 3,
              flexWrap: "wrap",
              maxWidth: 250,
              margin: "auto",
              textAlign: "center",
              img: {
                width: "80%",
              },
            }}
          >
            <Typography sx={{ width: "100%", fontWeight: 700 }} align="center">
              Join our community!
            </Typography>
            <Typography
              sx={{ fontSize: 11, width: "100%", mb: 1.5 }}
              align="center"
            >
              We’re here to support and innovate.
            </Typography>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://discord.gg/Z68pjTHfuK", "_blank")
              }
            >
              <img src={getImageUrl("discord.png")} />
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://twitter.com/streamfog", "_blank")
              }
            >
              <img src={getImageUrl("X.png")} />
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://youtube.com/@streamfog", "_blank")
              }
            >
              <img src={getImageUrl("youtube.png")} />
            </span>
          </Box>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
