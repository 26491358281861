import {
    
    Stepper, Step, StepLabel, StepConnector, stepConnectorClasses, styled, StepIconProps, 
  } from "@mui/material";

import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';




const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          "#7cd959",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          "#7cd959",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  
  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ ownerState }) => ({
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.completed && {
      background: "#7cd959",
  
    }),
    ...(ownerState.active && {
      background: "#7cd959",
  
    }),
  }));
  
  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
  
    const icons: { [index: string]: React.ReactElement } = {
      1: <LooksOneRoundedIcon />,
      2: <LooksTwoRoundedIcon />,
  
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  
  const steps = [
    `Create <span style='color:#7cd959'>OBS Browser Source</span> `,
    `Click <span style='color:#7cd959'>"Start OBS Browser source"</span> on your dashboard`,
  ];
  
  const Steps=()=> {
    return (
      <div className="d-flex flex-column justify-content-between align-items-center">
        <h6>STEPS:</h6>
        <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}><span className="stepper_label_tutorial" dangerouslySetInnerHTML={{ __html: label }}></span></StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
  
  export default Steps;
  
  