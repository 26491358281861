import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../components/formComponents/Input";
import { Button } from "@mui/material";
import { WebcamContext } from "../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { logOut } from "../redux/slices/authenticationSlice";
import { Logout } from "@mui/icons-material";

const MyProfile = () => {
  const { loggedInUser } = useSelector((state: any) => state.authentication);
  const [edit, setEdit] = useState(false);
  const { setshowBurgerMenu }: any = useContext(WebcamContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = async () => {
    setshowBurgerMenu(false);
    localStorage.clear();
    const cookies = Object.keys(Cookies.get());
    cookies.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    navigate("/");
    dispatch(logOut({ user: [], status: false, currentActivePlan: "" }));
  };

  return (
    <div className="my-profile_main">
      <h3 className="common-title">My Profile</h3>
      <div className="card-overlay-box p-4">
        <div className="row">
          <div className="col-lg-12">
            <div className="my-profile_userimg_wrapper">
              <img
                className="profile_user-img"
                src={loggedInUser?.profilePicture}
                alt="user_image"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <Input
              type="text"
              name="username"
              placeholder="Enter Your Name"
              className={edit ? "" : "white_bg_input"}
              value={loggedInUser?.userName}
              label="Display Name"
              wrapperClass="mt-4"
              disabled={edit ? false : true}
            />
          </div>
          <div className="col-lg-6">
            <Input
              type="text"
              name="description"
              placeholder="Enter Your Description"
              className={edit ? "" : "white_bg_input"}
              value={loggedInUser?.description}
              label="Description"
              wrapperClass="mt-4"
              disabled={edit ? false : true}
            />
          </div>
          <Button
            variant="contained"
            color="error"
            sx={{ py: 1.5, margin: "auto", mt: 2, color: "#fff" }}
            onClick={handleLogOut}
          >
            <Logout sx={{ verticalAlign: "top", mr: 1 }} />
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
