import { useState, useEffect, useContext, useRef } from "react";
import Button from "../formComponents/Button";
import {
  PlayCircleOutline,
  PauseCircleOutline,
  Filter2,
  Stars,
  PlayArrow,
  Pause,
} from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import moment, { Moment } from "moment";
import { getToaster } from "../../utils/general";
// import { WebcamContext } from "../../utils/context";
import { useDispatch, useSelector } from "react-redux";
import { WebcamContext } from "../../utils/context";
import axiosInstance from "../../axios.instance";
import { apiUrl, getImageUrl, getUserInfo } from "../../utils/getPaths";
import { endPoints } from "../../utils/apiEndPoints";
import {
  storeCurrentRunningAlert,
  storeScheduledAlertsAreRunning,
} from "../../redux/slices/dahsboardSlice";

interface OverlayButtonProps {
  overlayTitle: string;
  backgroundUrl: string;
  foregroundUrl: string;
  custom?: boolean;
  targetOriginScene?: any;
  thumbnail?: string;
  duration: number;
}

const OverlayButton = ({
  overlayTitle,
  backgroundUrl,
  foregroundUrl,
  thumbnail,
  targetOriginScene,
  custom,
  duration,
}: OverlayButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [running, setRunning] = useState(false);
  const [animationStatus, setAnimationStatus] = useState<any>(undefined);
  const dispatch = useDispatch();
  const [animationDuration, setAnimationDuration] = useState(0);
  const { currentPlayingAlert } = useSelector((state: any) => state.dashboard);
  const timeoutRef = useRef<NodeJS.Timeout | null>();
  console.log(duration);
  useEffect(() => {
    if (currentPlayingAlert !== overlayTitle) {
      setRunning(false);
      setAnimationDuration(0);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
  }, [currentPlayingAlert]);

  const ButtonText = (buttonName: string) => {
    return (
      <>
        <span>
          {custom && buttonName.split("&")[0].length > 16
            ? `${buttonName.split("&")[0].slice(0, 15)}...`
            : custom && buttonName.split("&")[0].length <= 16
            ? buttonName.split("&")[0]
            : ""}
          {!custom && buttonName.length > 16
            ? `${buttonName.slice(0, 15)}...`
            : !custom && buttonName.length <= 16
            ? buttonName
            : ""}
        </span>
        {loading ? (
          <CircularProgress
            className="ml-auto mr-2"
            size={14}
            style={{ fontSize: 10, color: "#fff" }}
          />
        ) : (
          <>
            {running && currentPlayingAlert === overlayTitle ? (
              <Pause className="ml-auto" style={{ marginLeft: 10 }} />
            ) : (
              <PlayArrow className="ml-auto" style={{ marginLeft: 10 }} />
            )}
          </>
        )}
      </>
    );
  };

  const handleSendPlayAkg = async (
    e?: React.SyntheticEvent,
    message?: string,
    messageTemplateSettings?: any
  ) => {
    if (e) e.preventDefault();
    try {
      setAnimationDuration(0);
      let params;
      params = {
        overlayTitle,
        background: backgroundUrl,
        foreground: foregroundUrl,
        messageTemplate: message,
        messageTemplateSettings,
        targetOriginScene: {
          to: targetOriginScene.targetScene,
          from: targetOriginScene.originScene,
          transition: targetOriginScene.transition,
          first: true,
        },
      };
      setLoading(true);
      const resp = await axiosInstance.post(
        apiUrl(endPoints.sendPlayAlertAkg),
        {
          userID: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          data: {
            typeOfEvent: "playAlert",
            params,
          },
        }
      );
      if (resp) {
        setLoading(false);
        if (resp.data.message == "animation started.") {
          setRunning(true);
          setAnimationDuration(duration);
          dispatch(storeCurrentRunningAlert(overlayTitle));
          timeoutRef.current = setTimeout(() => {
            setRunning(false);
            setAnimationDuration(0);
            dispatch(storeCurrentRunningAlert(""));
          }, duration * 1000);
        } else {
          getToaster("warn", resp.data.message);
        }
      }
    } catch (error) {
      getToaster("error", "Error while playing the overlay.");
      throw error;
    }
  };

  return (
    <>
      <Button
        className={custom ? "custom_alert-button" : ""}
        title={"Play animation"}
        text={ButtonText(overlayTitle)}
        type="button"
        onClick={(e) => {
          handleSendPlayAkg(e);
        }}
        style={{
          position: "relative",
          padding: "22px 15px 22px 85px",
        }}
        image={thumbnail ? thumbnail : getImageUrl("streamfog_placeholder.png")}
        imageStyle={{
          maxWidth: `${!thumbnail ? "60px" : ""}`,
          objectFit: `${!thumbnail ? "contain" : "cover"}`,
          background: `${!thumbnail ? "#1a1d1f" : ""}`,
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "8px 0 0 8px",
        }}
        buttonAnimation={{ animation: true, duration: animationDuration }}
      />
    </>
  );
};

export default OverlayButton;
