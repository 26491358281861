import { useContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import moment from "moment";
import { WebcamContext } from "../../utils/context";
import styled from "@emotion/styled";
import { IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const CollpaseSidebarDiv = styled("div")({
  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translateY(-50%)",
  svg: {
    color: "rgba(255, 255, 255, 0.4)",
  },
  "@media(max-width: 991px)": {
    left: -3,
  },
  "@media(max-width: 767px)": {
    display: "none",
  },
});

const AfterLogin = ({ children }: any) => {
  const { sidebarCollapsed, setSidebarCollapsed }: any =
    useContext(WebcamContext);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!sidebarCollapsed) {
      setTimeout(() => {
        setReady(true);
      }, 250);
    } else {
      setReady(false);
    }
  }, [sidebarCollapsed]);

  return (
    <div
      className={
        sidebarCollapsed
          ? `main-app sidebar-collapsed ${ready ? "ready" : ""}`
          : `main-app ${ready ? "ready" : ""}`
      }
    >
      {window.innerWidth < 768 && <Header />}
      <Sidebar />
      <div className="main-app-wrapper">
        <CollpaseSidebarDiv>
          <IconButton onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
            {sidebarCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </CollpaseSidebarDiv>
        <div className="main-app-insider">{children}</div>
        <div style={{ opacity: 0.2, textAlign: "center", padding: 7 }}>
          <small>
            <span style={{ fontSize: 16, verticalAlign: "middle" }}>©</span>{" "}
            Copyright {new Date().getFullYear()} | Streamfog - v1.5.0
          </small>
        </div>
      </div>
    </div>
  );
};

export default AfterLogin;
