import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getUserInfo } from "../../utils/getPaths";
import { statics } from "../../utils/statics";

interface AlertBoxProps {
  override?: boolean;
  height?: number | string;
  width?: number | string;
}

export const AlertBox: React.FC<AlertBoxProps> = ({
  override = false,
  height,
  width,
}) => {
  const [isURLVisible, setIsURLVisible] = useState(false);
  const url = `${statics.STREAMING_URL}/${getUserInfo()?._id}&${
    getUserInfo()?.twitchId
  }`;
  const [showAlert, setShowAlert] = useState(false);

  const handleToggleVisibility = () => {
    setIsURLVisible(!isURLVisible);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000); // Display the alert for 2 seconds
  };

  const getCameraResolution = () => {
    if (override) {
      return `${width}px | ${height}px`;
    } else {
      const resolution = localStorage
        .getItem("selectedWebCamResolution")
        ?.split(",");
      const width = resolution?.[0] || "";
      const height = resolution?.[1] || "";
      return `${width}px | ${height}px`;
    }
  };

  return (
    <div className="obs-info" style={{ maxWidth: 380, marginTop: -5 }}>
      <div>
        <div className="d-flex align-items-center flex-wrap">
          <p
            className="obs-title w-100"
            style={{ marginBottom: 14, fontSize: 16 }}
          >
            Camera URL for OBS:
          </p>
          <div
            className="d-flex justify-content-between obs-border px-2 py-1 align-items-center"
            style={{ width: "calc(100% - 80px)" }}
          >
            <div
              className={isURLVisible ? "clear-text mr-3" : "blurred-text mr-3"}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {url}
            </div>
            {isURLVisible ? (
              <Visibility className="icon" onClick={handleToggleVisibility} />
            ) : (
              <VisibilityOff
                className="icon"
                onClick={handleToggleVisibility}
              />
            )}
          </div>
          <button onClick={handleCopyLink} className="copy_btn">
            {showAlert ? "copied!" : "copy"}
          </button>
        </div>
        <p style={{ fontSize: 14, marginTop: 15 }} className="mb-0">
          Use this URL to add a Browser Source in OBS.
        </p>

        <p style={{ fontSize: 14, marginTop: 11 }} className="mb-1">
          Width:{" "}
          <span style={{ color: "#7cd959" }}>
            {override ? width : getCameraResolution().split(" | ")[0]}
          </span>{" "}
          | Height:{" "}
          <span style={{ color: "#7cd959" }}>
            {override ? height : getCameraResolution().split(" | ")[1]}
          </span>
        </p>
      </div>
    </div>
  );
};
