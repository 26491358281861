import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface PaymentStates {
  billingDetails: any;
}

const initialState: PaymentStates = {
  billingDetails: {},
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    storeBillingDetails: (state, action) => {
      state.billingDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeBillingDetails } = paymentSlice.actions;

export default paymentSlice.reducer;
