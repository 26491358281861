import React, { useState, useEffect } from "react";
import { apiUrl, getImageUrl } from "../../utils/getPaths";
import { CircularProgress } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { statics } from "../../utils/statics";
import {
  storeChangeRoutes,
  storeLoggedInUser,
} from "../../redux/slices/authenticationSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { endPoints } from "../../utils/apiEndPoints";
import { useNavigate } from "react-router-dom";
import { cleanLocalStorage, getToaster } from "../../utils/general";
import Cookies from "js-cookie";
import Input from "../../components/formComponents/Input";
import Button from "../../components/formComponents/Button";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const cookies = Object.keys(Cookies.get());

    cookies.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    cleanLocalStorage();
  }, []);

  const [uniqueID, setUniqueID] = useState("");

  const handleRemoteLogin = async () => {
    if (!uniqueID) {
      getToaster("error", "Please enter your unique ID first.");
      return;
    } else {
      if (!uniqueID.includes("&") || uniqueID.split("&")[0].length !== 24) {
        getToaster("error", "Invalid unique ID.");
        return;
      } else {
        setLoading(true);
        debugger;
        await axios
          .post(apiUrl(endPoints.remoteLogin), {
            objID: uniqueID.split("&")[0],
            twitchID: uniqueID.split("&")[1],
          })
          .then((res) => {
            if (res) {
              console.log(res, "remote login resp");
              let access_token = res.data.accessToken;
              let refresh_Token = res.data.refreshToken;
              const expirationTime = new Date();
              Cookies.set("accessToken", access_token, {
                expires: expirationTime.setTime(
                  expirationTime.getTime() + 15 * 24 * 60 * 60 * 1000
                ),
              });
              Cookies.set("refreshToken", refresh_Token, {
                expires: expirationTime.setTime(
                  expirationTime.getTime() + 30 * 24 * 60 * 60 * 1000
                ),
              });
              localStorage.setItem("userinfo", JSON.stringify(res.data));
              dispatch(
                storeLoggedInUser({
                  user: res.data,
                  status: true,
                })
              );
              setLoading(false);
              getToaster(
                "success",
                "Welcome back, " + res.data.userName + " !"
              );
              dispatch(storeChangeRoutes(true));
              setTimeout(() => {
                navigate("/dashboard");
              }, 500);
            }
          })
          .catch((err) => {
            getToaster("error", err.response.data.message);
            console.log(err);
          });
      }
    }
  };

  return (
    <div className="form_wrapper">
      <script src="../assets/js/eventsub.js"></script>
      <h3 className="auth_title">Remote Login</h3>
      <form>
        <Input
          name="uniqueId"
          placeholder="Enter Secret Key"
          value={uniqueID}
          onChange={(e) => setUniqueID(e.target.value)}
          type="text"
        />
        <Button
          onClick={() => {
            handleRemoteLogin();
          }}
          loader={{ isThere: true, loaderValue: loading }}
          text="Go to Dashboard"
          type="button"
          className="global_button"
        />
      </form>
    </div>
  );
};

export default Login;
