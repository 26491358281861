import { useState, useEffect, useRef } from "react";
import Button from "../formComponents/Button";
import {
  PlayCircleOutline,
  PauseCircleOutline,
  Pause,
  PlayArrow,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { getToaster } from "../../utils/general";
// import { WebcamContext } from "../../utils/context";
import { useSelector } from "react-redux";
import axiosInstance from "../../axios.instance";
import { apiUrl, getImageUrl, getUserInfo } from "../../utils/getPaths";
import { endPoints } from "../../utils/apiEndPoints";
import SwitchComponent from "../formComponents/Switch";

interface FaceMeshButtonProps {
  overlayTitle: string;
  custom?: boolean;
  thumbnail: string;
  textureUrl: string;
  avatarId: string;
  activeAvatar: {
    avatarId: string;
    assetUrl: string;
  };
  setActiveAvatar: any;
  isSettingsSet: boolean;
}

const FaceMeshButton = ({
  overlayTitle,
  custom,
  thumbnail,
  textureUrl,
  avatarId,
  activeAvatar,
  setActiveAvatar,
  isSettingsSet,
}: FaceMeshButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { currentPlayingAlert } = useSelector((state: any) => state.dashboard);

  useEffect(() => {
    setIsActive(activeAvatar.avatarId === avatarId);
  }, [activeAvatar]);

  const ButtonText = (buttonName: string) => {
    return (
      <>
        <span>
          {custom && buttonName.split("&")[0].length > 16
            ? `${buttonName.split("&")[0].slice(0, 15)}...`
            : custom && buttonName.split("&")[0].length <= 16
            ? buttonName.split("&")[0]
            : ""}
          {!custom && buttonName.length > 16
            ? `${buttonName.slice(0, 15)}...`
            : !custom && buttonName.length <= 16
            ? buttonName
            : ""}
        </span>
        <SwitchComponent
          checked={isActive}
          onChange={isActive ? handleDisableFacemesh : handleSetFacemesh}
          className="ml-auto"
        />
      </>
    );
  };

  const handleDisableFacemesh = async () => {
    await axiosInstance
      .put(`${apiUrl(endPoints.settings)}/${getUserInfo()?.twitchId}`, {
        settings: {
          activeAvatar: {
            avatarId: "",
            assetUrl: "",
          },
        },
        sendEffect: {
          endPoint: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          typeOfEvent: "facesDisabled",
        },
      })
      .then((res) => {
        if (res.data) {
          setActiveAvatar({
            avatarId: "",
            assetUrl: "",
          });
        }
      })
      .catch((err) => {
        getToaster("error", "Error while saving scheduled alerts.");
        throw err;
      });
  };

  const handleSetFacemesh = async (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    if (!isSettingsSet) {
      getToaster(
        "warn",
        "Please select your camera from camera settings first."
      );
      return;
    }
    let avatarIdToSend = "";
    let urlToSend = "";
    if (!isActive) {
      setIsActive(true);
      avatarIdToSend = avatarId;
      urlToSend = textureUrl;
    } else {
      setIsActive(false);
    }

    await axiosInstance.put(
      `${apiUrl(endPoints.settings)}/${getUserInfo()?.twitchId}`,
      {
        settings: {
          activeAvatar: {
            avatarId: avatarIdToSend,
            assetUrl: urlToSend,
          },
        },
        sendEffect: {
          endPoint: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          typeOfEvent: "faceMesh",
          params: {
            textureUrl: urlToSend,
          },
        },
      }
    );

    setActiveAvatar({
      avatarId: avatarIdToSend,
      assetUrl: urlToSend,
    });
    setLoading(false);
  };

  return (
    <>
      <Button
        className={custom ? "custom_alert-button" : ""}
        title={"Play face mesh"}
        text={ButtonText(overlayTitle)}
        type="button"
        onClick={(e) => {
          isActive ? handleDisableFacemesh() : handleSetFacemesh(e);
        }}
        style={{
          position: "relative",
          padding: "22px 15px 22px 85px",
          background: `${isActive ? "rgba(141, 194, 58, 0.1)" : ""}`,
        }}
        image={thumbnail ? thumbnail : getImageUrl("streamfog_placeholder.png")}
        imageStyle={{
          maxWidth: `${!thumbnail ? "60px" : ""}`,
          objectFit: `${!thumbnail ? "contain" : "cover"}`,
          background: `${!thumbnail ? "#1a1d1f" : ""}`,
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
          borderRadius: "8px 0 0 8px",
        }}
      />
    </>
  );
};

export default FaceMeshButton;
