import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../../utils/getPaths";

export interface authStates {
  opened?: string;
  isLoggedIn?: boolean;
  loggedInUser?: [];
  currentActivePlan?: string;
  changeRoutes?: boolean;
}

const initialState: authStates = {
  opened: "login",
  isLoggedIn: false,
  currentActivePlan: getUserInfo()?.currentActivePlan,
  loggedInUser: [],
  changeRoutes: false,
};

export const authSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    manageUI: (state, action) => {
      state.opened = action.payload;
    },
    storeChangeRoutes: (state, action) => {
      state.changeRoutes = action.payload;
    },
    storeLoggedInUser: (state, action) => {
      state.loggedInUser = action?.payload?.user;
      state.isLoggedIn = action?.payload?.status;
      state.currentActivePlan = action?.payload?.currentActivePlan;
    },
    logOut: (state, action) => {
      state.loggedInUser = action?.payload?.user;
      state.isLoggedIn = action?.payload?.status;
      state.currentActivePlan = action?.payload?.currentActivePlan;
    },
  },
});

// Action creators are generated for each case reducer function
export const { manageUI, storeLoggedInUser, logOut, storeChangeRoutes } =
  authSlice.actions;

export default authSlice.reducer;
