import React, { useEffect, useContext, useState, Suspense, lazy } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import BeforeLogin from "./pages/BeforeLogin";
import { useDispatch, useSelector } from "react-redux";
import AfterLogin from "./modules/AfterLogin/AfterLogin";
import {
  storeChangeRoutes,
  storeLoggedInUser,
} from "./redux/slices/authenticationSlice";
import { ToastContainer } from "react-toast";
import Cookies from "js-cookie";
import moment from "moment";
import { cleanLocalStorage, getToaster } from "./utils/general";
import Payment from "./pages/Payment";
import { statics } from "./utils/statics";
import axiosInstance from "./axios.instance";
import { WebcamContext } from "./utils/context";
import { apiUrl, getImageUrl, getUserInfo } from "./utils/getPaths";
import { endPoints } from "./utils/apiEndPoints";
import ARStudio from "./pages/ARStudio";
import Login from "./modules/BeforeLogin/Login";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import MarketPlace from "./pages/MarketPlace";
import MyProfile from "./pages/MyProfile";
import Help from "./pages/Help";
import MarketPlaceNew from "./pages/MarketPlaceNew";

function App() {
  const dispatch = useDispatch();
  const token = Cookies.get("accessToken");
  // const token = localStorage.getItem("accessToken");
  const user = localStorage.getItem("userinfo");
  const { changeRoutes, isLoggedIn } = useSelector(
    (state: any) => state.authentication
  );
  const { setOriginStream }: any = useContext(WebcamContext);

  const checkIsStreamOnOff = async () => {
    try {
      const resp = await axiosInstance.post(
        apiUrl(endPoints.sendPlayAlertAkg),
        {
          userID: getUserInfo()?._id + "&" + getUserInfo()?.twitchId,
          data: {
            typeOfEvent: "checkOriginStreamStatus",
          },
        }
      );
      if (resp.data.message === "STREAMING_TRUE") {
        setOriginStream(true);
      } else {
        setOriginStream(false);
      }
    } catch (error) {
      getToaster("error", "Error while checking origin stream is on or not.");
      throw error;
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      checkIsStreamOnOff();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (token && user) {
      dispatch(
        storeLoggedInUser({
          status: true,
          user: JSON.parse(user),
        })
      );
    }
  }, []);

  useEffect(() => {
    if (token && user) {
      dispatch(
        storeLoggedInUser({
          status: true,
          user: JSON.parse(user),
        })
      );
    }
  }, [changeRoutes]);

  return (
    <div className="App">
      <Routes>
        {!token || !user ? (
          <Route
            element={
              <BeforeLogin>
                <Outlet />
              </BeforeLogin>
            }
          >
            <Route path="/" element={<Login />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Route>
        ) : (
          <>
            <Route
              element={
                <AfterLogin>
                  <Outlet />
                </AfterLogin>
              }
            >
              <Route path="/ar-studio" element={<ARStudio />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/help" element={<Help />} />
              <Route path="/*" element={<Navigate to="/ar-studio" />} />
            </Route>
          </>
        )}
      </Routes>
      <ToastContainer delay={4000} position="top-center" />
    </div>
  );
}

export default App;
