import { createContext, useRef, useState } from "react";
export const WebcamContext = createContext(null);

const WebcamProvider = ({ children }: any) => {
  const [originStream, setOriginStream] = useState<boolean | null>(null);
  const [showBurgerMenu, setshowBurgerMenu] = useState(false);

  return (
    <WebcamContext.Provider
      //@ts-ignore
      value={{
        originStream,
        setOriginStream,
        showBurgerMenu,
        setshowBurgerMenu,
      }}
    >
      {children}
    </WebcamContext.Provider>
  );
};

export default WebcamProvider;
